(function () {
    'use strict';
    // -- RequireJS config --
    requirejs.config({
        baseUrl: '/assets/js',
        packages: [
            {
                name: 'crypto-js',
                location: '../lib/crypto-js',
                main: 'crypto-js'
            }
        ],
        paths: {
            'crypto-js/hmac-sha256': '../lib/crypto-js/hmac-sha256',
            'blockadblock': 'vendors/blockadblock',
            'postMessage': 'vendors/postMessage',
            'modernizr': '../lib/modernizr/modernizr',
            'jquery': '../lib/jquery/jquery',
            'jquery.ui': '../plugins/jquery-ui-1.11.4.stoep/jquery-ui',
            'moment.timezone': '../lib/moment-timezone/moment-timezone-with-data-2010-2020',
            'moment': '../lib/momentjs/moment',
            'js.cookie': '../lib/js-cookie/src/js.cookie',
            'retinajs': '../lib/retinajs/retina',
            'bootstrap.all': '../lib/bootstrap/dist/js/bootstrap',
            'bootstrap.select': '../lib/bootstrap-select/dist/js/bootstrap-select',
            'lodash': '../lib/lodash/dist/lodash'
        },
        shim: {
            'blockadblock': ['jquery'],
            'retinajs': ['jquery'],
            'postMessage': ['jquery'],
            'bootstrap.select': ['bootstrap.all'],
            'bootstrap.all': ['jquery'],
            'jquery.ui': ['jquery'],
            'modernizr': {
                exports: 'Modernizr'
            }
        }
    });

    requirejs.onError = function (err) {
        console.log(err);
    };

    requirejs(["app/main"]);
})();

define("main", function(){});

