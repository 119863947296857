define('app/main',[
    'jquery',
    'app/datepicker',
    'app/postMessageConfig',
    'app/menu',
    'app/analytics',
    'app/carousel',
    'app/retina',
    'app/calendar',
    'app/searchFilters',
    'app/forms',
    'app/ie9fix',
    'app/ie10fix',
    'app/blockadblockConfig',
    'app/basketData',
    'app/app',
    'app/friendForm',
    'app/cookieWall'
], function ($, datepicker, postMessageConfig, menu, analytics, carousel, retinajs, calendar, searchFilters, forms, ie9fix, ie10fix,
             blockadblockConfig, basketData, app, friendForm, cookieWall) {
    'use strict';
    $(document).ready(function () {
        console.log('Loaded app/main.js');
        basketData.init();
        blockadblockConfig.init();
        datepicker.init();
        menu.init();
        analytics.init();
        carousel.init();
        postMessageConfig.init();
        retinajs.init();
        calendar.init();
        searchFilters.init();
        forms.init();
        ie9fix.init();
        ie10fix.init();
        app.init();
        friendForm.init();
        cookieWall.init();
    });
});
