define('app/carousel',[
    'jquery',
    'modernizr',
    'bootstrap.all'
], function ($) {
    'use strict';

    return {
        init: function () {
            console.log('Start initializing carousel');

            var interval = 5000;
            var transition = 1500;
            $.fn.carousel.Constructor.TRANSITION_DURATION = transition;
            var carou =$('#prodItemSlideshow');
            carou.carousel({
                interval: interval
            });

            $("#startYoutbeCarousel.clickable").click(function () {
                console.log("CLICK");
                playVideo();
            });

            function playVideo() {
                var activeSlide = $("#startYoutbeCarousel .item.active");
                var ytID = getYoutubeID($("#startYoutbeCarousel").data('youtubeurl'));
                if (typeof ytID === 'string' && ytID.length > 1) {
                    carou.carousel("pause");
                    $("#startYoutbeCarousel.clickable").removeClass('clickable');
                    $("#startYoutbeCarousel .carousel-caption.youtubecaption").removeClass('youtubecaption');
                    var youtubeURL = "https://www.youtube.com/embed/" + ytID + "?&theme=dark&autohide=1&modestbranding=1&showinfo=0&rel=0&vq=hd1080&autoplay=1";
                    activeSlide.html('<div class="embed-responsive embed-responsive-16by9"><iframe src=' + youtubeURL + ' frameborder="0" class="embed-responsive-item" allowfullscreen></iframe></div>');
                }
            }

            function getYoutubeID(text) {
                if (typeof text === 'string') {
                    var re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
                    return text.replace(re, '$1');
                }
                else {
                    return null;
                }
            }
        }

    };
});
