/* jshint ignore:start */
define('app/analytics',[
    'jquery',
], function ($) {
    'use strict';

    var orderUrlRegex = /.*\/bestel\/.+\/\d{4}-\d{2}-\d{2}\/\d{2}:\d{2}/i;

    var decorate = function () {
        $("a.btn").each(function () {
            var link = $(this);
            var href = $(link).attr('href');
            if (orderUrlRegex.test(href)) {
                // eslint-disable-next-line
                ga('linker:decorate', link[0]); // jshint ignore:line
            }
        });

        $("a.forceGaLink").each(function () {
            var link = $(this);
            // eslint-disable-next-line
            ga('linker:decorate', link[0]); // jshint ignore:line
        });
    };


    return {
        init: function () {
            console.log('Start initializing analytics.js...');

            // run once right away
            decorate();

            // run every 30s
            setInterval(decorate, 30000);
        }
    };
});
