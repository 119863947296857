define('app/friendForm',[
    'jquery',
    'lodash',
    atob('Y3J5cHRvLWpzL2htYWMtc2hhMjU2')
], function ($, _, f) {
    'use strict';

    var generalFormError = 'Er is iets misgegaan. Het formulier is niet verzonden. <a href="#" class="linkcolor" onclick="parent.location.reload();">Probeer het nogmaals</a>.';

    var collectField = function (v, defaultValue) {
        if(_.isUndefined(defaultValue)) {
            defaultValue = null;
        }
        if (!_.isEmpty(v)) {
            var newV = _.trim(v);
            if (!_.isEmpty(newV)) {
                return newV;
            } else {
                return defaultValue;
            }
        } else {
            return defaultValue;
        }
    };

    var outputMessage = function (msg) {
        $('#friendFormDiv').html(msg);
    };

    var handleFormSubmission = function (e) {
        e.preventDefault();

        var form = $(this);
        var formFields = form.serializeArray();

        var email = collectField((_.find(formFields, {name: 'email'}) || {}).value);
        var cid = 'c64281955';

        if (!_.isEmpty(email) && !_.isEmpty(cid)) {
            $.ajax({
                type: 'GET',
                url: '/api/friend-token' + atob("P2g9") + f(email+cid, atob("ZGMzMjJiMDBjMTc0NDgwNjk4NjZiMWMxZDdmODRkOTY=")) + '&email=' + encodeURIComponent(email) + '&cid=' + encodeURIComponent(cid),
                dataType: 'json',
                success: function (result) {
                    if (_.isObject(result) && _.isString(result.token)) {
                        if (!_.isEmpty(result.token)) {
                            outputMessage(
                                '<div class="row">' +
                                    '<div class="col-md-12">' +
                                        '<p>Bedankt. U staat inderdaad met dit e-mailadres bekend als vriend van Theater de Stoep.</p>' +
                                        '<p>Klik hieronder om uw kaarten te bestellen.</p>' +
                                    '</div>' +
                                '</div>'+
                                '<div class="row">' +
                                    '<div class="col-md-12">' +
                                        '<a class="btn btn-lg btn-primary btn-block btn-sales-default " title="Bestel" ' +
                                        'href="/voorstellingen?ct=' + result.token + '" role="button">Klik hier om verder te gaan</a>' +
                                    '</div>' +
                                '</div>'
                            );
                        } else {
                            outputMessage(
                                '<p>Helaas! U staat op dit moment niet (meer) bij ons geregistreerd als vriend.</p>' +
                                '<p>Mocht dit ten onrechte zijn, neemt u dan contact op met onze kassa: 0181-652222</a>.</p>' +
                                '<button type="submit" class="btn btn-primary margin-right-1em" onclick="parent.location.reload();">Probeer het opnieuw</button>'
                            );
                        }
                    } else {
                        outputMessage(generalFormError);
                    }
                },
                error: function () {
                    outputMessage(generalFormError);
                }
            });
        } else {
            outputMessage(generalFormError);
        }
    };

    return {
        init: function () {
            console.log('Start initializing friendForm');
            $('form#friendForm').on('submit', handleFormSubmission);
        }
    };
});
