define('app/calendar',[
    'jquery',
    'moment',
    'jquery.ui',
    'moment.timezone'
], function ($, moment) {
    'use strict';
    var dayNamesMin = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];
    return {
        init: function () {
            console.log('Start initializing calendar');

            //calendar
            $("div.sidebar .datepicker-containers").datepicker({
                minDate: 0,
                onSelect: function () {
                    var theDate = new Date(Date.parse($(this).datepicker("getDate")));
                    var dateFormatted = $.datepicker.formatDate("yy-mm-dd", theDate);
                    updateKal(this.id, dateFormatted, true);
                }
            });

            function updateKal(calendarId, dateFormatted, showloader) {
                var selectorDynamic = "#dynamictoday" + calendarId;
                var apiUrl = ApiRouter.controllers.ApiController.calendarWidget(calendarId, dateFormatted).url;
                if (showloader === true) {
                    $(selectorDynamic).html('<div class="row"><div class="col-md-12"><br/><div class="preloader"></div></div></div>');
                }
                $.ajax({
                    type: "GET",
                    url: apiUrl,
                    success: function (result) {
                        if ($.isArray(result.productions)) {
                            if (result.productions.length > 0) {
                                var htmlcontent = '';
                                if (!result.isClickedDate &&
                                    result.messageNotClicked &&
                                    $.type(result.messageNotClicked) === "string") {
                                    htmlcontent += '<p class="noBottomMargin">' + result.messageNotClicked + '</p>';
                                }

                                $(result.productions).each(function (index, prod) {
                                    htmlcontent += processProduction(prod, result, dateFormatted);
                                });
                                $(selectorDynamic).html(htmlcontent);
                            }
                            else {
                                $(selectorDynamic).html('<div class="row"><div class="col-md-12">' +
                                    '<p>Geen voorstellingen op de gekozen datum en daarna.</p>' +
                                    '</div></div>');
                            }
                        }
                        else {
                            $(selectorDynamic).html('<div class="row"><div class="col-md-12">' +
                                '<p>Probeer het nog eens.</p>' +
                                '</div></div>');
                        }

                    },
                    error: function () {
                        $(selectorDynamic).html('<div class="row"><div class="col-md-12">' +
                            '<p>Probeer het nog eens.</p>' +
                            '</div></div>');
                    }
                });
            }

            function processProduction(prod, widgetResp) {
                var ret = "";
                if (prod && $.isPlainObject(prod) && $.isArray(prod.events) && prod.events.length > 0) {
                    var url = ApiRouter.controllers.ProductionsController.item(prod.urlPath).url;
                    var moreInfoText = widgetResp.moreInfoText || "Meer informatie";

                    ret += '<div class="row"><div class="col-md-4">';
                    ret += '<a href="' + url + '">';
                    ret += '<img src="/media/images/' + prod.thumbImageFile.filename + '"/>';
                    ret += '</a>';
                    ret += '</div>';
                    ret += '<div class="col-md-8">';
                    ret += '<h2 class="no-margin-top"><a href="' + url + '">' + (prod.heading ? prod.heading : "") + '</a></h2>';
                    if (prod.subHeading) {
                        ret += '<h3><a href="' + url + '">' + prod.subHeading + '</a></h3>';
                    }
                    if (prod.events[0].location && $.isPlainObject(prod.events[0].location) && prod.events[0].location.name) {
                        ret += '<p class="valign-top">' + prod.events[0].location.name + '<br />';
                    }
                    for (var i = 0, len = prod.events.length; i < len; i++) {
                        var htmlBreak = '';
                        if (i > 0) {
                            htmlBreak = '<br />';
                        }
                        var formattedDateTime = getFormattedDate(prod.events[i].startDate);
                        ret += htmlBreak + formattedDateTime + ' uur';
                    }
                    ret += '<a href="' + url + '" class="pull-right valign-top margin-clear btn btn-sm btn-default-transparent"> ' + moreInfoText + '</a>';
                    ret += '</p>';

                    ret += '</div>';
                    ret += '</div>';
                }

                return ret;
            }

            function getFormattedDate(dtstr) {
                moment.locale('nl-NL');
                var momentDate = moment.tz(dtstr, 'Europe/Amsterdam');
                var weekDay = dayNamesMin[momentDate.format('E') - 1];
                var month = $.datepicker.regional.nl.monthNamesShort[momentDate.month()];
                return weekDay + " " + momentDate.tz('Europe/Amsterdam').format("D ").toLowerCase() + month + momentDate.tz('Europe/Amsterdam').format(" YYYY -  HH:mm").toLowerCase();
            }

        }
    };

});

