/* jshint -W100 */

/* todo: prevText and nextText are using dangerous characters according to jshint (remove the above line to see the error) */

define('app/datepicker',[
    'jquery',
    'jquery.ui'
], function ($) {
    'use strict';

    return {
        init : function () {
            console.log('Start initializing datepicker.js');

            $.datepicker.regional.nl = {
                closeText: 'Sluiten',
                prevText: 'â†',
                nextText: 'â†’',
                currentText: 'Vandaag',
                monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni',
                    'juli', 'augustus', 'september', 'oktober', 'november', 'december'
                ],
                monthNamesShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun',
                    'jul', 'aug', 'sep', 'okt', 'nov', 'dec'
                ],
                dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
                dayNamesShort: ['zon', 'maa', 'din', 'woe', 'don', 'vri', 'zat'],
                dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
                weekHeader: 'Wk',
                dateFormat: 'dd-mm-yy',
                firstDay: 1,
                isRTL: false,
                showMonthAfterYear: false,
                yearSuffix: ''
            };
            $.datepicker.setDefaults($.datepicker.regional.nl);
            $( "#datepicker" ).datepicker();
        }
    };

});
