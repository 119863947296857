define('app/retina',[
    'jquery',
    'retinajs'
], function () {
    'use strict';
    return {
        init : function () {
            console.log('Start initializing retina.js...');
        }
    };
});
