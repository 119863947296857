define('app/searchFilters',[
    'jquery'
], function ($) {
    'use strict';

    var isEmpty = function (val) {
        if (val === undefined || val === null || val === "") {
            return true;
        }

        if ($.isArray(val) && val.length === 0) {
            return true;
        }

        return $.isEmptyObject(val);
    };

    var onFilterChange = function () {
        var triggerdField = this;
        var formFields = $("#programma-filter").serializeArray();
        var postfix = {};
        var zalen = [];
        var genres = [];
        $.each(formFields, function () {

            if (this.name === "q" && !isEmpty(this.value)) {
                postfix.q = "?q=" + encodeURIComponent(this.value);
            }

            else if (this.name === "maandJaar" && !isEmpty(this.value)) {
                postfix.maandJaar = this.value.replace('%2F', '/');
            }

            else if ((this.name === "genres") && !isEmpty(this.value)) {

                if ($.isArray(this.value)) {
                    $.each(this.val, function () {
                        genres.push(encodeURIComponent(this));
                    });
                }
                else if ($.type(this.value) === "string") {
                    genres.push(encodeURIComponent(this.value));
                }

            }

            else if ((this.name === "zalen") && !isEmpty(this.value)) {

                if ($.isArray(this.value)) {
                    $.each(this.val, function () {
                        zalen.push(encodeURIComponent(this));
                    });
                }
                else if ($.type(this.value) === "string") {
                    zalen.push(encodeURIComponent(this.value));
                }
            }
        });

        var url = '/voorstellingen';
        if (!isEmpty(postfix.q) && triggerdField.name === "q") {
            url += postfix.q;
        }
        else {

            if (genres.length > 0) {
                if (isEmpty(postfix.q)) {
                    postfix.genres = "?";
                } else {
                    postfix.genres = "&";
                }
                $.each(genres, function () {
                    postfix.genres += "genres=" + this + "&";
                });
                postfix.genres = postfix.genres.substring(0, postfix.genres.length - 1);
            }

            if (zalen.length > 0) {
                if ((!postfix.genres || postfix.genres.length === 0) && (!postfix.q || postfix.q.length === 0)) {
                    postfix.zalen = "?";
                } else {
                    postfix.zalen = "&";
                }
                $.each(zalen, function () {
                    postfix.zalen += "zalen=" + this + "&";
                });
                postfix.zalen = postfix.zalen.substring(0, postfix.zalen.length - 1);
            }

            if (!isEmpty(postfix.maandJaar)) {
                url += "/" + postfix.maandJaar;
            }
            if (!isEmpty(postfix.q)) {
                url += postfix.q;
            }
            if (!isEmpty(postfix.genres)) {
                url += postfix.genres;
            }
            if (!isEmpty(postfix.zalen)) {
                url += postfix.zalen;
            }

        }
        location.href = url;
    };

    var registerSearchHandling = function (id) {
        var form = $(id);
        var input = form.find('input[name="q"]');
        form.change(function () {
            var q = input.val();
            if (q && typeof q === "string" && q.length > 1) {
                this.submit();
            }
        });
        form.on("submit", function (event) {
            var q = input.val();
            if (q === undefined || q === null || q === "" || q.length <= 1) {
                event.preventDefault();
            }
        });
    };

    return {
        init: function () {
            console.log('Start initializing searchFilters');

            // search
            registerSearchHandling('#globalSearch');
            registerSearchHandling('#globalSearchMobile');

            // programma
            $("#programma-filter").submit(function (event) {
                event.preventDefault();
            });

            $("#resetAgendaform").click(function () {
                location.href = "/voorstellingen";
            });

            $('#prodFilterSearchTerm').change(onFilterChange);

            $('.selectpicker').on('changed.bs.select', onFilterChange);

        }
    };

});
