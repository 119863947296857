define('app/postMessageConfig',[
    'jquery',
    'js.cookie',
    'app/base64',
    'postMessage'
], function ($, Cookie, Base64) {
    'use strict';

    console.log("loaded postMessageConfig");

    return {
        init: function () {
            var if_height = 100;
            var extraHeight = 110;
            $.receiveMessage(function (e) {
                var h = Number(e.data.replace(/.*if_height=(\d+)(?:&|$)/, '$1'));
                var iframe = $('#bestelframe');
                var current_height = iframe.height();
                console.log('Message received.');
                if (iframe && !isNaN(h) && h > 0 && h !== if_height) {
                    var setToHeight = h + extraHeight;
                    if (setToHeight != current_height) {
                        iframe.height(if_height = setToHeight);
                        $('html,body').scrollTop(0);
                    }
                } else {
                    // try basket
                    try {
                        var data = JSON.parse(e.data);
                        var nrOfTickets = parseInt(data.nrOfTickets);
                        var totalAmount = data.totalAmount;
                        console.log('received postMessage basketData:');
                        console.log(data);
                        if (nrOfTickets > 0 && totalAmount && $.isNumeric(totalAmount.toString().replace(',', '.'))) {
                            $('#basketDataInfo').html('<br class="visible-xs"/> (' + nrOfTickets + ' items - € ' + totalAmount.replace('.', ',') + ')');
                            var cookieData = {
                                basketId : 0,
                                action : 'updateBasket',
                                nrOfTickets : parseInt(nrOfTickets),
                                totalAmount : totalAmount,
                                eventIds : [],
                                referer : ''
                            };
                            Cookie.set('basketData', Base64.encode(JSON.stringify(cookieData)));
                        } else {
                            $('#basketDataInfo').html('');
                            Cookie.remove('basketData');
                        }
                    } catch(error){
                        // something went wrong
                        $('#basketDataInfo').html('');
                        Cookie.remove('basketData');
                    }
                }

            }, 'https://destoep.ticketmatic.com');
        }
    };
});
