/* globals blockAdBlock:true */
define('app/blockadblockConfig',[
    'jquery',
    'blockadblock'
], function ($) {
    'use strict';

    return {
        init: function () {
            console.log('Start initializing blockadblockConfig.js');

            $(document).ready(function () {

                var tmIframe = $('#tmiframe');
                if (tmIframe.length > 0) {
                    if (typeof blockAdBlock === 'undefined') {
                        adBlockDetected();
                    } else {
                        blockAdBlock.setOption({debug: false});
                        blockAdBlock.onDetected(adBlockDetected);
                        blockAdBlock.check();
                    }
                }

            });

            function adBlockDetected() {
                var tmiframe = $('#tmiframe');
                var url = tmiframe.data('tmurl');
                if (url && url.length > 0) {
                    tmiframe.prepend("<p class='regular_text'>Het lijkt er op dat u gebruik maakt van een zogenaamde 'Ad Blocker'. Om toch kaarten te bestellen, <a href=\""+url+"\">kunt u op <strong>deze link</strong> kliken</a>.</p>");
                    window.location.href = url;
                }
            }
        }
    };
});
